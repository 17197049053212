export type TSplitTimesLabels = Record<'pace500' | 'pace1000' | 'pace1500' | 'pace2000' | 'pace2500' | 'paceLast500', string>;

export interface IEventResponseType {
  type: string;
  trackId: string;
  raceNr: string;
  time: string;
  timestamp: string;
  prelKmTime: { time: string };
  paceName: TSplitTimesLabels;
}

export enum EEventTypes {
  TimeToStart = "TimeToStart",
  TimeToDef = "TimeToDef",
  RaceStarted = "RaceStarted",
  RaceFinished = "RaceFinished",
  SplitTimes = "SplitTimes",
  RaceRestarted = "RaceRestarted",
  Pagar = 'Pagar'
}

